<template>
  <div class="aio-layout">
    <div class="aio-section">
      <div class="aio-header">
        <h1>All in One</h1>
        <p>
          Here, we can look at all the information of the students all in one.
        </p>
        <form action="" class="aio-search">
          <!-- <div class="border-form"> -->
          <div>
            <v-student-search-field
              @onClear="onClear"
              @onSelectStudent="selectStudent"
              :calculateProfilePercentage="true"
            ></v-student-search-field>
            <!-- <input
              class="aio-input"
              type="text"
              id="fname"
              name="fname"
              value="Student Name"
            /><br /> -->
            <!-- <div class="button-section">
              <button type="clear-button" style="padding-right: 14px">
                CLEAR
              </button>
              <button
                class="search-box"
                type="button"
                onclick="alert('Hello world!')"
              >
                SEARCH
              </button>
            </div> -->
          </div>
        </form>

        <div class="class-section">
          <h1>Download :</h1>
          <button class="class-box" type="button" :disabled="student_information ? false:true" @click="downloadPersonalBatchRecord()">
            Personal / Batch Record
          </button>
          <button class="class-box" type="button" disabled="true">Exam Record</button>
          <button class="class-box" type="button" disabled="true">Library Record</button>
          <button class="class-box" type="button" disabled="true">Billing Record</button>
          <button class="class-box" type="button" :disabled="student_information ? false:true " @click="downloadIdCard()">Download ID / Card</button>

        </div>
      </div>

      <div class="all-in-one">
        <div class="row" v-if="student_information">
          <div class="col-3">
            <div class="aio-personal-info">
              <div class="personal-header">
                <h1>Personal Information</h1>
                <div class="student-img">
                  <img :src="student_information.avatar" alt="" />
                </div>
                <h2 class="student-name">
                  {{ student_information.name || "" }}
                </h2>
                <p class="student-deignation">Student</p>
              </div>

              <div class="profile-progress">
                <div class="progress-detail">
                  <h1>PROFILE PROGRESS</h1>
                  <h2>
                    {{ student_information.details.complete_percentage }}%
                  </h2>
                </div>

                <div class="progress">
                  <div class="background-progress"></div>
                </div>
              </div>

              <div class="general-information" v-if="student_information">
                <h1>General Information</h1>
                <div class="general">
                  <h1>Gender</h1>
                  <h1 class="general-change-color">
                    {{ student_information.details.gender || "" }}
                  </h1>
                </div>

                <div class="general">
                  <h1>DOB</h1>
                  <h1 class="general-change-color">
                    {{ student_information.details.personal.dob || "" }}
                  </h1>
                </div>

                <div class="general">
                  <h1>Admission Date</h1>
                  <h1 class="general-change-color">
                    {{ student_information.details.admission_date || "" }}
                  </h1>
                </div>
              </div>

              <div class="general-information" v-if="student_information">
                <h1>Academic Information</h1>
                <div class="general">
                  <h1>Class</h1>
                  <h1 class="general-change-color">
                    {{ student_information.details.academic.grade || "" }}
                  </h1>
                </div>

                <div class="general">
                  <h1>Batch</h1>
                  <h1 class="general-change-color">
                    {{ student_information.details.academic.batch || "" }}
                  </h1>
                </div>

                <div class="general">
                  <h1>Section</h1>
                  <h1 class="general-change-color">
                    {{ student_information.details.academic.section || "" }}
                  </h1>
                </div>
              </div>

              <div class="general-information" v-if="student_information">
                <h1>Guardian Information</h1>
                <div class="general">
                  <h1>Guardian Name</h1>
                  <h1 class="general-change-color">
                    {{ student_information.guardian.name }}
                  </h1>
                </div>

                <div class="general">
                  <h1>Father's Name</h1>
                  <h1 class="general-change-color">
                    {{ student_information.guardian.parent_name }}
                  </h1>
                </div>

                <div class="general">
                  <h1>Relation</h1>
                  <h1 class="general-change-color">
                    {{ student_information.guardian.relation }}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div class="col-9 pl-0 pb-0 pr-0">
            <div class="aio-exam-section">
              <AIOHeaderCategory
                @onTabChange="onTabChangeAction"
              ></AIOHeaderCategory>

              <AIOExam
                v-if="displayTab === 'exam' && student_information"
                :key="examCompKey"
                :enrollCode="student_information.enroll_code"
                :gradeId="student_information.details.grade_id"
                :gradeName="student_information.details.academic.grade"
                :sectionId="student_information.details.academic.section_id"
                :enrollId="student_information.enroll_id"
              >
              </AIOExam>

              <AIOBilling v-else-if="displayTab === 'billing'"></AIOBilling>

              <AIOLibrary v-else-if="displayTab === 'library'"></AIOLibrary>

              <AIOActivityReport
                v-else-if="displayTab === 'activity'"
              ></AIOActivityReport>
            </div>
          </div>
        </div>
        <!-- <div class="row" v-else>
          <div class="col-3">
            <div class="aio-personal-info">
              <div class="personal-header">
                <h1>Personal Information</h1>
                <div class="student-img">
                  <img src="" alt="" />
                </div>
                <h2 class="student-name"></h2>
                <p class="student-deignation">Student</p>
              </div>

              <div class="profile-progress">
                <div class="progress-detail">
                  <h1>PROFILE PROGRESS</h1>
                  <h2>75%</h2>
                </div>

                <div class="progress">
                  <div class="background-progress"></div>
                </div>
              </div>

              <div class="general-information" >
                <h1>General Information</h1>
                <div class="general">
                  <h1>Gender</h1>
                  <h1 class="general-change-color" ></h1>
                </div>

                <div class="general">
                  <h1>DOB</h1>
                  <h1 class="general-change-color"></h1>
                </div>

                <div class="general">
                  <h1>Admission Date</h1>
                  <h1 class="general-change-color"></h1>
                </div>
              </div>

              <div class="general-information">
                <h1>Academic Information</h1>
                <div class="general">
                  <h1>Class</h1>
                  <h1 class="general-change-color"></h1>
                </div>

                <div class="general">
                  <h1>Batch</h1>
                  <h1 class="general-change-color"></h1>
                </div>

                <div class="general">
                  <h1>Section</h1>
                  <h1 class="general-change-color"></h1>
                </div>
              </div>

              <div class="general-information" >
                <h1>Guardian Information</h1>
                <div class="general">
                  <h1>Guardian Name</h1>
                  <h1 class="general-change-color"></h1>
                </div>

                <div class="general">
                  <h1>Father's Name</h1>
                  <h1 class="general-change-color"></h1>
                </div>

                <div class="general">
                  <h1>Relation</h1>
                  <h1 class="general-change-color"></h1>
                </div>
              </div>
            </div>
          </div>

          <div class="col-9 pl-0 pb-0 pr-0">
            <div class="aio-exam-section">
              <AIOHeaderCategory
                @onTabChange="onTabChangeAction"
              ></AIOHeaderCategory>

              <AIOExam v-if="displayTab === 'exam'"></AIOExam>

              <AIOBilling v-else-if="displayTab === 'billing'"></AIOBilling>

              <AIOLibrary v-else-if="displayTab === 'library'"></AIOLibrary>

              <AIOActivityReport
                v-else-if="displayTab === 'activity'"
              ></AIOActivityReport>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import AIOBilling from "./pages/AioBillingold.vue";
import AIOExam from "./pages/AioExam.vue";
import AIOHeaderCategory from "./pages/AioHeaderCategory.vue";
import AIOLibrary from "./pages/AioLibrary.vue";
import AIOActivityReport from "./pages/AioActivityReport.vue";
import {passDataToExternalDomain, redirectDownloadUrl} from "../../../library/helpers"
export default {
	components: {
		AIOBilling,
		AIOExam,
		AIOHeaderCategory,
		AIOLibrary,
		AIOActivityReport,
	},
	data: () => ({
		examCompKey: 0,
		student_exam_search: false,
		student_information: "",
		displayTab: "exam",
		dueReport: "",

		series: [44, 55, 41, 17, 15],
		series: [
			{
				data: [21, 22, 10, 28, 16, 21, 13, 30],
			},
		],
		chartOptions: {
			chart: {
				width: "100%",
				type: "donut",
			},
			dataLabels: {
				enabled: false,
			},

			legend: {
				position: "bottom",
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						abels: {
							show: false,
						},
					},
				},
			],
		},
	}),
	methods: {
		onTabChangeAction(tabType) {
			this.displayTab = tabType;
		},
		selectStudent(student) {
			this.student_information = student;
			if (student.guardian.family !== null && student.guardian.family.length > 0) {
				let father_details = student.guardian.family.find(
					(o) => o.relation === "Father"
				);
				this.student_information.guardian.parent_name = father_details.name;
				this.student_information.guardian.parent_relation =
          father_details.relation;

				this.examCompKey += 1;
			}
		},

		onClear() {
			this.student_information = "";
		},

		downloadIdCard(){
			let batch_data = JSON.parse(localStorage.getItem("batch"));
			// passDataToExternalDomain('reporting')
			setTimeout(() => {
				const url = redirectDownloadUrl({
					uri:'/print/card/identity-card',
					queryString:`gradeId=${this.student_information.details.grade_id}&sectionId=${this.student_information.details.academic.section_id}&enrollId=${this.student_information.enroll_id}&id_card_template_id=1&is_hs=${batch_data.is_hs}`});
			}, 200);
		},
		downloadPersonalBatchRecord(){
			const url = redirectDownloadUrl({
				uri:'/print/card/personal-batch-record',
				queryString:`enrollId=${this.student_information.enroll_id}&enroll_code=${this.student_information.enroll_code}`});
		}

	},
};
</script>
<style lang="scss"></style>
