<template>
  <div>
    <div class="exam-content-section" v-if="displayTab === 'library'">
      <div class="row">
        <div class="col-9">
          <div class="exam-description">
            <div class="header-description">
              <h1>Exam</h1>
              <p>
                During school year (SY) 2012-2013, the Bureau of Indian
                Education (BIE) continued its efforts to improve the validity
                and reliability of data reporting.
              </p>
            </div>

            <div class="exam-marks">
              <div class="exam-partitions">
                <div class="exam-scores">
                  <h1>3.6</h1>
                  <h2>GPA</h2>
                </div>

                <div class="exam-scores">
                  <h1>A+</h1>
                  <h2>Rank</h2>
                </div>

                <div class="exam-scores">
                  <h1>2079/12/10</h1>
                  <h2>Exam Date</h2>
                </div>

                <div class="exam-scores">
                  <h1>600</h1>
                  <h2>Full Marks</h2>
                </div>

                <div class="exam-scores">
                  <h1>300</h1>
                  <h2>Pass Marks</h2>
                </div>

                <div class="exam-scores">
                  <h1>480</h1>
                  <h2>Total Scored</h2>
                </div>
              </div>

              <div class="exam-term">
                <a href="">
                  <h1>First Term Exam</h1>
                </a>
              </div>
            </div>

            <div class="analysis-status">
              <div class="download-status">
                <h1><a href="">Download Routine</a></h1>
                <h1><a href="">Download Ledger</a></h1>
                <h1><a href="">Download Result</a></h1>
              </div>

              <div class="exam-analysis">
                <a href="">
                  <h1>Overall Analysis</h1>
                </a>
              </div>
            </div>
          </div>

          <!-- <div class="scored-marks">
            <div class="scored-marks-header">
              <v-container fluid>
                <v-row align="center">
                  <v-col cols="6">
                    <v-select
                      outlined
                      dense
                      :items="types"
                      required
                      class="pa-0"
                      label="Select Library"
                      v-model="selectType"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </div>

            <div class="performance-analysis" v-if="selectType == 'marks'">
              <div class="student-performance">
                <h1>Performance Analysis</h1>
                <p>Student</p>

                <div class="analysis-score">
                  <div class="score">
                    <h2>A+</h2>
                    <h1>Grade</h1>
                    <div class="content"></div>
                  </div>

                  <div class="score">
                    <h2>B+</h2>
                    <h1>Hygiene</h1>
                    <div
                      style="width: 200px; background-color: #74b773"
                      class="content"
                    ></div>
                  </div>
                  <div class="score">
                    <h2>B</h2>
                    <h1>Punctuality</h1>
                    <div
                      style="width: 156px; background-color: #ecd246"
                      class="content"
                    ></div>
                  </div>
                  <div class="score">
                    <h2>C+</h2>
                    <h1>Discipline</h1>
                    <div
                      style="width: 220px; background-color: #e25947"
                      class="content"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="scored-marks-table">
                <h1>Scored Marks</h1>
                <table style="width: 100%">
                  <tr>
                    <th style="border-left: 1px solid #e2e2e2" colspan="1"></th>
                    <th style="border-left: 1px solid #e2e2e2" colspan="1"></th>
                    <th style="border-left: 1px solid #e2e2e2" colspan="1"></th>
                    <th
                      style="border: 1px solid #e2e2e2; text-align: center"
                      colspan="2"
                    >
                      Obtained Grade
                    </th>
                    <th colspan="1"></th>
                  </tr>

                  <tr>
                    <th style="border-right: 1px solid #e2e2e2">S.N</th>
                    <th style="border-right: 1px solid #e2e2e2">Subjects</th>
                    <th style="border-left: 1px solid #e2e2e2">FM</th>
                    <th style="border: 1px solid #e2e2e2">TH</th>
                    <th style="border: 1px solid #e2e2e2">PR</th>
                    <th style="width: 14%">Grade Point</th>
                  </tr>

                  <tr>
                    <td>1.</td>
                    <td>Nepali</td>
                    <td>30</td>
                    <td>B+</td>
                    <td>-</td>
                    <td>3.2</td>
                  </tr>

                  <tr>
                    <td>2.</td>
                    <td>English / Grammer</td>
                    <td>30</td>
                    <td>B</td>
                    <td>-</td>
                    <td>2.8</td>
                  </tr>

                  <tr>
                    <td>3.</td>
                    <td>Mathematics</td>
                    <td>30</td>
                    <td>B+</td>
                    <td>-</td>
                    <td>3.2</td>
                  </tr>

                  <tr>
                    <td>4.</td>
                    <td>Science</td>
                    <td>30</td>
                    <td>C</td>
                    <td>-</td>
                    <td>2</td>
                  </tr>

                  <tr>
                    <td>5.</td>
                    <td>Social Studies</td>
                    <td>30</td>
                    <td>B</td>
                    <td>-</td>
                    <td>2.8</td>
                  </tr>

                  <tr>
                    <td>6.</td>
                    <td>General Knowledge</td>
                    <td>30</td>
                    <td>A+</td>
                    <td>-</td>
                    <td>4</td>
                  </tr>

                  <tr>
                    <td>7.</td>
                    <td>Conversation</td>
                    <td>30</td>
                    <td>A</td>
                    <td>-</td>
                    <td>3.6</td>
                  </tr>

                  <tr>
                    <td>8.</td>
                    <td>Handwriting</td>
                    <td>30</td>
                    <td>B</td>
                    <td>-</td>
                    <td>2.8</td>
                  </tr>

                  <tr>
                    <td>9.</td>
                    <td>Life Skill</td>
                    <td>30</td>
                    <td>B</td>
                    <td>-</td>
                    <td>2.8</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="certificate-section" v-if="selectType == 'crt'">
              <div class="certificate-template">
                <div class="certificate-icon">
                  <img src="images/img/badge.png" alt="" />
                </div>

                <div class="about-certificate">
                  <h1>Character Certificate</h1>

                  <div class="certificate-year">
                    <h2>From : 2073</h2>
                    <h2>To : 2079</h2>
                  </div>
                </div>
              </div>

              <div class="certificate-printer-section">
                 <div class="certificate-result">
                <div class="printer-icon">
                  <img src="images/img/printing.png" alt="" />
                </div>

                <div class="printer-content">
                  <h1>GPA: 3.2</h1>
                  
                </div>
                <div class="certificate-grade">
                  <span class="material-icons tick">
                  check_circle
                  </span>
                    <h1>Excellent</h1>
                  </div>
                             

              </div>
              </div>
             
            </div>
          </div> -->
        </div>

        <div class="col-3 pl-0 pr-0">
          <div class="exam-diagram">
            <h1>STUDENTS SCORED MARKS OVERVIEW</h1>
             <DonutChart></DonutChart>

          <br />
          <br />

          <BarChart></BarChart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectType: "marks",
    types: [
      {
        text: "Scored Marks",
        value: "marks",
      },
      {
        text: "Routine",
        value: "rut",
      },
      {
        text: "Subject Marks",
        value: "sbm",
      },
      {
        text: "Certificate",
        value: "crt",
      },
    ],

    displayTab: "exam",
    dueReport: "",

     series: [44, 55, 41, 17, 15],
    chart: {
      type: "donut",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  }),
};
</script>
<style lang="scss">
</style>